import styled, { createGlobalStyle } from 'styled-components'
import { Media } from '@renderbus/common/theme'
import RoboBlackItalic from '../font/Robo-BlackItalic.ttf'
import RobotoBold from '../font/Roboto-Bold.ttf'
import RobotoRegular from '../font/Roboto-Regular.ttf'
import { ToastWrap } from '@renderbus/common/components/toast/atoms'
import ToastSuccess from '../images/render-contest-2023/toast-success.png'

export const DetailStyle = createGlobalStyle`
  @font-face {
    font-family: 'Robo-BlackItalic';
    src: url(${RoboBlackItalic});
    font-style: normal;
    font-weight: normal;
    font-display: fallback;
  }
  @font-face {
    font-family: 'Roboto-Bold';
    src: url(${RobotoBold});
    font-style: normal;
    font-weight: normal;
    font-display: fallback;
  }
  @font-face {
    font-family: 'Roboto-Regular';
    src: url(${RobotoRegular});
    font-style: normal;
    font-weight: normal;
    font-display: fallback;
  }
  html {
    background-color: #F6F6F6;
    height: 100vh;
  }
  .hide {
    display: none !important;
  }
  .hideInMobile {
    ${Media.lessThan(Media.small)} {
      display: none !important;
    }
  }
  .hideInPC {
    ${Media.greaterThan(Media.small)} {
      display: none !important;
    }
  }
  ${ToastWrap} {
    display: flex;
    align-items: center;
    width: 327px;
    height: 47px;
    line-height: 47PX;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.05);
    border-radius: 2px 2px 2px 2px;
    color: #2F2F2F;
    font-size: 14px;
    top: 50% !important;
    &::before {
      display: inline-block;
      content: '';
      width: 22px;
      height: 22px;
      background-image: url(${ToastSuccess});
      margin: 0 12px 0 10px;
    }
    &::after{
      display: inline-block;
      content: '';
      position: absolute;
      width: 3px;
      height: 39px;
      background: #219B6A;
      border-radius: 10px 10px 10px 10px;
    }
  }
`

export const WorkBanner = styled.div`
  height: 400px;
  position: relative;
  ${Media.lessThan(Media.small)} {
    img {
      max-width: 90%;
    }
  }
`

export const DetailContainer = styled.div`
  padding-top: 144px;
  text-align: center;
  ${Media.lessThan(Media.small)} {
    margin-top: 12vw;
    padding-top: 0;
    text-align: left;
    background: #f6f6f6;
    padding-bottom: 60px;
  }
`

export const DetailTitle = styled.h1`
  font-size: 42px;
  color: #fafafa;
  font-weight: 700;
  ${Media.lessThan(Media.small)} {
    color: #3b3b3b;
    padding-left: 16px;
    font-size: 16px;
    margin-top: 20px;
  }
`
export const WorkInfo = styled.div`
  font-size: 14px;
  color: #999999;
  font-weight: 350;
  ${Media.lessThan(Media.small)} {
    padding: 0 16px;
    font-size: 11px;
    img {
      width: 16px;
    }
  }
`
export const WorkUser = styled.div`
  margin: 32px 0 60px;
  font-size: 14px;
  color: #ffffff;
  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 8px;
  }
  ${Media.lessThan(Media.small)} {
    color: #3b3b3b;
    padding-left: 16px;
    font-size: 12px;
    margin: 13px 0 12px;
  }
`
export const Line = styled.div`
  width: 100%;
  height: 0;
  opacity: 0.3;
  border: 1px solid #53596d;
`
export const WorkDetail = styled.div`
  max-width: 1200px;
  margin: 80px auto 40px;
  text-align: left;
  font-size: 19px;
  font-weight: 350;
  color: #ffffff;
  line-height: 40px;
  letter-spacing: 1px;
  ${Media.lessThan(Media.small)} {
    color: #3b3b3b;
    padding: 20px 16px 0;
    margin: 0;
    font-size: 14px;
    line-height: unset;
  }
`
export const VideoContain = styled.div`
  width: 1200px;
  height: auto;
  margin: auto;
  position: relative;
  cursor: pointer;
  video {
    width: 100%;
    height: 100%;
  }
  .hide {
    display: none;
  }
  ${Media.lessThan(Media.small)} {
    width: 100%;
    height: auto;
    position: relative;
    cursor: pointer;
  }
`
export const SidebarContent = styled.div`
  position: fixed;
  right: 40px;
  top: 50%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  > div:first-child {
    &:hover {
      ::before {
        content: '123231';
        position: absolute;
        right: 100px;
        width: 100px;
        height: 50px;
        background-color: red;
      }
    }
  }
`

export const PopoverWrapper = styled.div`
  position: absolute;
  width: 162px;
  height: 191px;
  background: #4248b9;
  top: 18px;
  left: -10px;
  transform: translate(-100%, -50%);
  border-radius: 6px;
  color: white;
  display: none;
  text-align: center;
  padding: 16px;
  font-size: 12px;
  font-weight: 400;
  .share-code {
    width: 121px;
    height: 121px;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    border: 1px solid #7f8df0;
    margin: 0 auto 8px;
    display: flex;
  }
  &.share {
    height: 180px;
    top: 90px;
    left: -10px;
  }
  img {
    width: 113px;
    height: 113px;
    border-radius: 6px 6px 6px 6px;
    border: 1px solid #7f8df0;
    margin: auto;
  }
  ${Media.lessThan(Media.small)} {
    &.votemb {
      display: block;
      top: -100px;
      left: unset;
      display: flex;
      flex-direction: column;
      right: -150px;
      img {
        width: 113px;
        height: 110px;
        margin: auto;
      }
    }
  }
`

export const SidebarItem = styled.li`
  list-style: none;
  width: 50px;
  height: 50px;
  background: #ffffff;
  border-radius: 5px 5px 5px 5px;
  opacity: 1;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  img[data-icon-hover] {
    display: none;
  }
  :hover {
    img[data-icon] {
      display: none;
    }
    img[data-icon-hover] {
      display: unset;
    }
    ${PopoverWrapper} {
      display: block;
    }
  }
`

export const IconContainer = styled.div``

export const NavBottom = styled.div`
  height: 60px;
  background: #ffffff;
  width: 100%;
  display: flex;
  justify-content: end;
  position: absolute;
  bottom: 0;
  img {
    width: 32px;
    margin: auto 10px;
  }
  .vote {
    width: 81px;
  }
  ${Media.lessThan(Media.small)} {
    position: fixed;
    bottom: 0;
    z-index: 999;
  }
`

export const PageFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 73px;
  width: 1200px;
  height: 63px;
  position: relative;
  left: 50%;
  transform: translate(-50%);
  border-top: 1px solid #999999;
  color: #b0b6bc;
  img {
    margin: 10px 11px 0 5px;
    transform: translate(0, -50%);
  }
  a:hover {
    color: #5195f5;
  }
  ${Media.lessThan(Media.small)} {
    width: 92vw;
    height: auto;
    margin-top: 12.93vw;
    flex-direction: column;
    justify-content: space-around;
    font-size: 2.39vw;
    padding-top: 2.93vw;
    padding-bottom: 5.33vw;
    margin-top: 30px;
    p {
      margin: 2px;
    }
    img {
      margin: 1.6vw 3.33vw 0 5px;
      width: 1.86vw;
    }
  }
`
