/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect, useRef } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useLocation } from '@reach/router'
import QRCode from 'qrcode.react'
import Helmet from 'react-helmet'

import { SEO, Billboard, Toast } from '@renderbus/common/components'
import axios from 'axios'

import {
  RCHeader,
  HeaderLogo,
  HeaderItem,
  SignButton,
  NavigateMenuIcon,
  NavigateMenu,
  NavigateMenuItem,
} from './rc.atom'
import {
  DetailContainer,
  DetailTitle,
  WorkInfo,
  WorkUser,
  Line,
  WorkDetail,
  SidebarContent,
  SidebarItem,
  IconContainer,
  PopoverWrapper,
  VideoContain,
  DetailStyle,
  NavBottom,
  PageFooter,
} from './rc-detail.atom'
import EyesIcon from '../images/render-contest-2023/eyes-icon.png'
import SidebarIcon1 from '../images/render-contest-2023/sidebar-icon1.png'
import SidebarIcon2 from '../images/render-contest-2023/sidebar-icon2.png'
import SidebarIcon3 from '../images/render-contest-2023/sidebar-icon3.png'
import SidebarClickIcon1 from '../images/render-contest-2023/sidebar-click-icon1.png'
import SidebarClickIcon2 from '../images/render-contest-2023/sidebar-click-icon2.png'
import SidebarClickIcon3 from '../images/render-contest-2023/sidebar-click-icon3.png'
import VoteIcon from '../images/render-contest-2023/vote-mb.png'
import ShareIcon from '../images/render-contest-2023/share-icon.png'
import Avatar from '../images/render-contest-2023/avatar.png'
import VoteQrCode from '../images/render-contest-2023/vote-qrcode.png'
import PoliceIcon from '../../../common/components/footer/images/police.png'
import { allRcWorkList } from '../constant/rc-result'

function RcDetail(props) {
  const {
    pageContext: { detailId },
  } = props

  const headerItemList = [
    {
      label: '赛事详情',
      link: '/rc.html#rc-time',
      left: '15.47vw',
    },
    {
      label: '赛事资料',
      link: '/rc.html#rc-content',
      left: '4.79vw',
    },
    {
      label: '常见问题',
      link: '/rc.html#rc-qa',
      left: '5.15vw',
    },
    {
      label: '首届回顾',
      link: 'https://www.rayvision.com/rrc.html',
      rel: 'nofollow',
      target: '_blank',
      left: '5.21vw',
    },
  ]
  const mobileMenuItemList = [
    {
      label: '赛果公布',
      link: '/rc.html#rc-result',
    },
    {
      label: '大赛引言',
      link: '/rc.html#rc-words',
    },
    {
      label: '大赛时间',
      link: '/rc.html#rc-time',
    },
    {
      label: '大赛组别',
      link: '/rc.html#rc-group',
    },
    {
      label: '大赛奖品',
      link: '/rc.html#rc-award',
    },
    {
      label: '评委阵容',
      link: '/rc.html#rc-judges',
    },
    {
      label: '大赛内容',
      link: '/rc.html#rc-content',
    },
    {
      label: '设计说明',
      link: '/rc.html#rc-design',
    },
    {
      label: '常见问题',
      link: '/rc.html#rc-qa',
    },
    {
      label: '返回顶部',
    },
  ]
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const video = useRef(null)
  const videoMb = useRef(null)
  const location = useLocation()
  const [viewCount, setViewCount] = useState(0)
  const [showVote, setShowVote] = useState(false)
  const [detailInfo, setDetailInfo] = useState({})

  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "render-contest-2023/render-bg.png" }) {
        ...fluidImage
      }
    }
  `)
  function handleScroll() {
    document.body.scrollTop = document.documentElement.scrollTop = 0
  }
  function copyUrl() {
    let text = location.href
    const el = document.createElement('input')
    el.setAttribute('value', text)
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    Toast.info('地址复制成功')
  }
  function clickVote() {
    setShowVote(!showVote)
  }
  function group(type) {
    switch (type) {
      case 0:
        return '学生组赛道'
      case 1:
        return '专业组赛道'
      default:
        break
    }
  }

  useEffect(() => {
    let info = allRcWorkList.find((item) => item.id === detailId)
    setDetailInfo(info)
  }, [detailId])

  useEffect(() => {
    axios({
      method: 'post',
      url: 'https://www.renderbus.com/rc-result/add-view',
      data: {
        link: detailInfo.link,
      },
    })
      .then((response) => {
        const { data } = response
        if (data) {
          setViewCount(data.viewCount)
        }
      })
      .catch((err) => {
        console.log(err)
        return
      })
      .finally(() => {
        return
      })
  }, [detailInfo])

  return (
    <React.Fragment>
      <Helmet>
        <script>
          {`
              var _hmt = _hmt || [];
              (function() {
                var hm = document.createElement("script");
                hm.src = "https://hm.baidu.com/hm.js?695fad530c8ccd7ba407ec0f01da7817";
                var s = document.getElementsByTagName("script")[0];
                s.parentNode.insertBefore(hm, s);
              })();
            `}
        </script>
      </Helmet>
      <SEO
        title={`${detailInfo?.workName} - 第二届3D渲染动画创作大赛｜算联视界,元生万象`}
        keywords={`${detailInfo?.name},${detailInfo?.workName},瑞云渲染动画创作大赛`}
        description={`${detailInfo?.workDescription?.slice(
          0,
          Math.min(80, detailInfo?.workDescription?.length),
        )}...`}
        shareImgSrc={`${detailInfo?.link}?x-oss-process=video/snapshot,t_10000,f_jpg,m_fast,w_386,h_217`}
        sharePostSlug='rc-details.html'
      />
      <RCHeader>
        <HeaderLogo />
        {headerItemList.map((item) => (
          <HeaderItem
            key={item.label}
            href={item.link}
            rel={item.rel}
            target={item.target}
            left={item.left}
          >
            {item.label}
          </HeaderItem>
        ))}
        <SignButton href='/rc2022/' id='rc-entrylist'>
          参赛作品展
        </SignButton>
        <NavigateMenuIcon
          isActive={!showMobileMenu}
          onClick={() => setShowMobileMenu(!showMobileMenu)}
        />
        <NavigateMenu isActive={showMobileMenu}>
          {mobileMenuItemList.map((item) =>
            item.link ? (
              <NavigateMenuItem href={item.link} key={item.label}>
                {item.label}
              </NavigateMenuItem>
            ) : (
              <NavigateMenuItem
                key={item.label}
                onClick={() => {
                  if (!item.link) {
                    handleScroll()
                  }
                }}
              >
                {item.label}
              </NavigateMenuItem>
            ),
          )}
        </NavigateMenu>
      </RCHeader>
      <DetailContainer className='hideInMobile'>
        <DetailTitle>{detailInfo?.workName}</DetailTitle>
        <WorkInfo>
          <span>原创作品</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <span>{group(detailInfo?.group)}</span>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <span>作品版权由作者解释</span>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <span>
            <img src={EyesIcon} alt='' />
            <span>&nbsp;{viewCount}</span>
          </span>
        </WorkInfo>
        <WorkUser>
          <img src={Avatar} alt='' />
          <span>{detailInfo?.name}</span>
        </WorkUser>
        <Line />
        <WorkDetail>{detailInfo?.workDescription}</WorkDetail>
        <VideoContain>
          <video ref={video} playsInline key={`pc_${detailInfo?.id}`} controls={true}>
            <source src={detailInfo?.link} />
          </video>
        </VideoContain>
        <Billboard fluid={data.banner.childImageSharp.fluid} style={{ height: 'auto' }} />
      </DetailContainer>
      <SidebarContent className='hideInMobile'>
        {detailInfo && detailInfo.isNominated ? (
          <SidebarItem>
            <IconContainer>
              <img src={SidebarIcon1} alt='' data-icon />
              <img src={SidebarClickIcon1} alt='' data-icon-hover />
            </IconContainer>
            <PopoverWrapper>
              <div className='share-code'>
                <img src={VoteQrCode} alt='wechat' />
              </div>
              <div>
                微信扫一扫
                <br />
                为喜欢的作品投票
              </div>
            </PopoverWrapper>
          </SidebarItem>
        ) : (
          <></>
        )}
        <SidebarItem>
          <IconContainer>
            <img src={SidebarIcon2} alt='' data-icon />
            <img src={SidebarClickIcon2} alt='' data-icon-hover />
          </IconContainer>
          <PopoverWrapper className='share'>
            <div className='share-code'>
              <QRCode
                id='qrCode'
                value={location.href}
                size={113} // 二维码的大小
                fgColor='#000000' // 二维码的颜色
                style={{
                  margin: 'auto',
                  padding: '2px',
                  background: '#fff',
                  borderRadius: '6px 6px 6px 6px',
                }}
              />
            </div>
            <div>扫一扫分享至微信</div>
          </PopoverWrapper>
        </SidebarItem>
        <SidebarItem onClick={() => handleScroll()}>
          <IconContainer>
            <img src={SidebarIcon3} alt='' data-icon />
            <img src={SidebarClickIcon3} alt='' data-icon-hover />
          </IconContainer>
        </SidebarItem>
      </SidebarContent>
      <DetailContainer className='hideInPC'>
        <VideoContain>
          <video
            ref={videoMb}
            playsInline
            key={`mb_${detailInfo.id}`}
            controls={true}
            poster={`${detailInfo?.link}?x-oss-process=video/snapshot,t_10000,f_jpg,m_fast,w_386,h_217`}
          >
            <source src={detailInfo?.link} />
          </video>
        </VideoContain>
        <DetailTitle>{detailInfo?.workName}</DetailTitle>
        <WorkUser>
          <img src={Avatar} alt='' />
          <span>{detailInfo?.name}</span>
        </WorkUser>
        <WorkInfo>
          <span>原创作品</span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
          <span>{group(detailInfo?.group)}</span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
          <span>作品版权由作者解释</span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
          <span>
            <img src={EyesIcon} alt='' />
            <span>&nbsp;{viewCount}</span>
          </span>
        </WorkInfo>
        <WorkDetail>{detailInfo?.workDescription}</WorkDetail>
        <PageFooter>
          <p className='footer-police'>
            <img src={PoliceIcon} alt='' />© 2022
            <a href='https://www.rayvision.com/' rel='nofollow'>
              深圳市瑞云科技股份有限公司
            </a>
            <a href='https://beian.miit.gov.cn/#/Integrated/index' target='_blank' rel='nofollow'>
              粤ICP备12028569号
            </a>
          </p>
          <p>中华人民共和国增值电信业务经营许可证编号：合字 B1-20200125</p>
        </PageFooter>
      </DetailContainer>
      <NavBottom className='hideInPC'>
        <img src={ShareIcon} onClick={() => copyUrl()} alt='' />
        <img src={VoteIcon} onClick={() => clickVote()} className='vote' alt='' />
        {showVote && (
          <PopoverWrapper className='votemb'>
            <div className='share-code'>
              <img src={VoteQrCode} alt='wechat' />
            </div>
            <div>
              长按识别二维码
              <br />
              为喜欢的作品投票
            </div>
          </PopoverWrapper>
        )}
      </NavBottom>
      <PageFooter className='hideInMobile'>
        <p className='footer-police'>
          <img src={PoliceIcon} alt='' />© 2022
          <a href='https://www.rayvision.com/' rel='nofollow'>
            深圳市瑞云科技股份有限公司
          </a>
          <a href='https://beian.miit.gov.cn/#/Integrated/index' target='_blank' rel='nofollow'>
            粤ICP备12028569号
          </a>
        </p>
        <p>中华人民共和国增值电信业务经营许可证编号：合字 B1-20200125</p>
      </PageFooter>
      <DetailStyle />
    </React.Fragment>
  )
}

export default RcDetail
